"use client";

import { useContext, useEffect } from "react";
import { SearchBoxContext } from "./searchBoxProvider";

interface ISearchBoxProps {
	id: string;
	ghostText: string;
	screenReaderLabel: string;
	maxLength: number;
	clearXSize?: number;
	rounded?: boolean;
	hasMaxWidth?: boolean;
	wideBorder?: boolean;
	hasExtraSmallTextSize?: boolean;
	brownBorder?: boolean;
}
const SearchBox: React.FC<ISearchBoxProps> = ({
	id,
	ghostText,
	screenReaderLabel,
	maxLength,
	clearXSize,
	rounded,
	hasMaxWidth,
	wideBorder,
	hasExtraSmallTextSize,
	brownBorder,
}) => {
	const { setSearchText, searchText, setDebouncedSearchText } = useContext(SearchBoxContext);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedSearchText((searchText || "").trim());
		}, 300);
		return () => clearTimeout(timeoutId);
	}, [searchText, setDebouncedSearchText]);

	return (
		<div className={`relative w-full mx-auto ${hasMaxWidth ? "xs:w-96" : ""}`}>
			<input
				type="text"
				maxLength={maxLength}
				placeholder={ghostText}
				value={searchText}
				onChange={e => setSearchText(e.target.value)}
				className={`w-full py-2 pl-3 pr-8 mx-auto ${
					hasExtraSmallTextSize ? "text-xs" : ""
				} bg-white ${wideBorder ? "border-2" : "border"} ${
					brownBorder ? "border-epic-light-brown" : ""
				} placeholder:text-slate-400 ${rounded ? "rounded-lg" : ""}`}
				id={id}
			/>
			<button
				type="button"
				className={`absolute right-1 top-2 h-8 w-8 group ${searchText == "" ? "hidden" : ""}`}
				onClick={() => setSearchText("")}
			>
				<span className="sr-only">{screenReaderLabel}</span>
				<ClearX
					clearXSize={clearXSize}
					className={`transition-colors ease-in-out fill-slate-700 ${
						hasExtraSmallTextSize ? "-mt-3" : "mt-[2px]"
					} hover:fill-slate-900 group-focus-within:fill-slate-900`}
				/>
			</button>
		</div>
	);
};
export default SearchBox;

const ClearX: React.FC<{ className: string; clearXSize?: number }> = ({ clearXSize, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={clearXSize || 20}
			height={clearXSize || 20}
			viewBox="0 0 384 512"
			fill="currentColor"
			{...rest}
		>
			<path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256l105.3-105.4z" />
		</svg>
	);
};
