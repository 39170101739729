"use client";

import { createContext, PropsWithChildren, useState } from "react";

interface ISearchBoxProviderProps {
	searchText: string;
	setSearchText: (searchText: string) => void;
	debouncedSearchText: string;
	setDebouncedSearchText: (searchText: string) => void;
}

export const SearchBoxContext = createContext<ISearchBoxProviderProps>({
	searchText: "",
	setSearchText: () => {},
	debouncedSearchText: "",
	setDebouncedSearchText: () => {},
});

export const SearchBoxProvider: React.FC<PropsWithChildren<ISearchBoxProviderProps>> = ({
	children,
}) => {
	const [searchText, setSearchText] = useState("");
	const [debouncedSearchText, setDebouncedSearchText] = useState("");
	return (
		<SearchBoxContext.Provider
			value={{ searchText, setSearchText, debouncedSearchText, setDebouncedSearchText }}
		>
			{children}
		</SearchBoxContext.Provider>
	);
};
